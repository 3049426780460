<template>
	<div class="row">
		<slot />
	</div>
</template>

<style scoped>
.row {
	box-sizing: border-box;
	margin-left: -0.75rem;
	margin-right: -0.75rem;
	margin-top: -0.75rem;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: calc(1.5rem - 0.75rem);
}

.row:last-child {
	margin-bottom: -0.75rem
}

.centered {
	justify-content: center;
}

.vcentered {
	align-items: center;
}
</style>

