<template>
	<div
		class="col"
		:class=" props.size "
	>
		<slot />
	</div>
</template>

<script lang="ts" setup>
const props = defineProps ( {
	size: {
		type: String,
		default: 'full'
	}
} )
</script>

<style scoped>
.col {
	box-sizing: border-box;
	display: block;
	flex-basis: 0;
	flex-shrink: 1;
	padding: 0.75rem;
	width: 100%;
}

.xs {
	flex: none;
	width: 25%;
}

.sm {
	flex: none;
	width: 33.3333333333%;
}

.md {
	flex: none;
	width: 50%;
}

.lg {
	flex: none;
	width: 66.6666666667%;
}

.xl {
	flex: none;
	width: 75%;
}

.full {
	flex: none;
}

@media screen and (min-width: 480px) and (max-width: 767px) {
	.fluid {
		width: 50%;
	}
}

@media screen and (max-width: 575px) {
	.fluid {
		width: 100%;
	}
}
</style>

